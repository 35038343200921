import React from 'react'
import {Col} from "reactstrap";
import ReactMarkdown from "react-markdown";


export default ({icon, title, children}) => (
  <Col className="d-flex flex-column justify-content-start align-items-center px-5 py-4">
    <img  src={icon.publicURL} alt={title} className="mb-2"/>
    <h5 className="text-center font-weight-bold py-3">{title}</h5>
    <ReactMarkdown source={children} className="text-center text-muted"/>
  </Col>
)
