import React from "react"
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {Container, Row} from 'reactstrap'

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import BloqueIconoTexto from "../components/bloqueIconoTexto"
import {LangContext} from "../components/kbusWrapper";

const QuienSomosPage = ({ data }) =>  (
  <LangContext.Consumer>
    { context => (
      <Layout>
        <SEO title={context.translate(data, 'quienSomos', 'titulo')} />
        <Container className="py-5 mb-5">
          <PageTitle title={context.translate(data, 'quienSomos', 'titulo')}/>
          <img
            src={context.translate(data, 'quienSomos', 'banner').publicURL}
            alt={context.translate(data, 'quienSomos', 'titulo')}
            className="w-100 my-5"
          />
          <h5 className="text-center font-weight-bold pb-3">{context.translate(data, 'quienSomos', 'encabezado1')}</h5>
          <ReactMarkdown source={context.translate(data, 'quienSomos', 'texto1')} className="text-muted"/>
          <div className="w-100 py-3"></div>
          <h5 className="text-center font-weight-bold pb-4">{context.translate(data, 'quienSomos', 'encabezado2')}</h5>
          <Row xs="1" md="3">
            {context.translate(data, 'quienSomos', 'iconos').map(item =>
              <BloqueIconoTexto
                key={item.id}
                icon={item.icono}
                title={item.titulo}
                children={item.descripcion}
              />
            )}
          </Row>
          <div className="w-100 py-3"></div>
          <h5 className="text-center font-weight-bold pb-3">{context.translate(data, 'quienSomos', 'encabezado3')}</h5>
          <ReactMarkdown source={context.translate(data, 'quienSomos', 'texto3')} className="text-muted"/>
          <div className="w-100 py-3"></div>
          <h5 className="text-center font-weight-bold pb-3">{context.translate(data, 'quienSomos', 'encabezado4')}</h5>
          <ReactMarkdown source={context.translate(data, 'quienSomos', 'texto4')} className="text-muted"/>
        </Container>
      </Layout>
    )}
  </LangContext.Consumer>
)

export default QuienSomosPage

export const pageQuery = graphql`  
  query {
    quienSomos: strapiQuienSomos {
      titulo
      banner {
        publicURL
      }
      encabezado1
      texto1
      encabezado2
      iconos {
        id
        titulo
        descripcion
        icono {
          publicURL
        }
      }
      encabezado3
      texto3
      encabezado4
      texto4
    }
    quienSomos_eus: strapiQuienSomosEus {
      titulo
      banner {
        publicURL
      }
      encabezado1
      texto1
      encabezado2
      iconos {
        id
        titulo
        descripcion
        icono {
          publicURL
        }
      }
      encabezado3
      texto3
      encabezado4
      texto4
    }
  }
`